function better_wpcf7_forms() {
  jQuery( '.wpcf7 .wpcf7-submit' ).each( function () {
    // Move ajax loader before submit button
    jQuery( this ).find( '+ .ajax-loader' ).insertBefore( this );
    // Change submit input to a button instead
    var submitHTML = this.outerHTML;
    var submitValue = this.value;
    submitHTML = submitHTML.replace( /^<input /, '<button ' );
    submitHTML = submitHTML.replace( /\/?>$/, '>' + submitValue + '</button>' );
    jQuery( this ).replaceWith( submitHTML );
  } );
}

function advancedForms() {
  var $ = jQuery;

  $( 'form.af-form' ).each( function () {
    var form = this;

    new AdvancedForm( form );

    if ( $( form ).has( '[data-form]' ) ) {
      var formName = $( '[data-form]', form ).data( 'form' );

      switch ( formName ) {

        // Change field labels when job type changes
        case 'form_submit_job':
          var fieldLabels = [];
          var defaultLabels = {};
          // Jobs
          fieldLabels[ 252 ] = {
            'expiry': 'Application Closing Date',
            'location': 'Location',
          };
          $( '.af-field[data-name="job_type"] .af-input select' ).on( 'change', function ( e ) {
            var triggerValue = $( this ).val();
            if ( typeof fieldLabels[ triggerValue ] !== 'undefined' ) {
              // Override label
              var newLabels = fieldLabels[ triggerValue ];
              Object.keys( newLabels ).forEach( function ( fieldName ) {
                var newLabel = newLabels[ fieldName ];
                var $labelElement = $( '.af-field[data-name="' + fieldName + '"] .af-label label', form );
                // Save the original label
                if ( typeof defaultLabels[ fieldName ] === 'undefined' ) {
                  defaultLabels[ fieldName ] = $labelElement.text();
                }
                $labelElement.text( newLabel );
              } );
            } else {
              // Change back to default
              Object.keys( defaultLabels ).forEach( function ( fieldName ) {
                var newLabel = defaultLabels[ fieldName ];
                var $labelElement = $( '.af-field[data-name="' + fieldName + '"] .af-label label', form );
                $labelElement.text( newLabel );
              } );
            }
          } ).triggerHandler( 'change' );
          break;

        case 'form_submit_event':
          break;

      }

    }

  } );

  $( 'form.woocommerce-EditAccountForm' ).each( function () {
    new AdvancedForm( this );
  } );

  acfCountryStatesAjax();
}

// Disable AJAX validation on certain pages
function disableACFValidation() {
  let e = new MouseEvent( 'click' );
  e.$el = jQuery( '.woocommerce-EditAccountForm :submit' );

  acf.validation.click_ignore.call( acf.validation, e );
}

function acfCountryStatesAjax() {
  acf.add_filter( 'select2_ajax_data', function ( data, args, $input ) {
    if ( data.field_key === 'field_user_profile_billing_state' ) {
      data.country = jQuery( 'select[name="acf\\[field_user_profile_billing_country\\]"]:not(:disabled)' ).val();
    }
    return data;
  } );
}

( function acfHooks() {
  if ( typeof acf === 'undefined' ) { return; }

  acf.add_filter( 'time_picker_args', function ( args, $field ) {
    args.showSecond = false;
    return args;
  } );

} )();
