jQuery( function ( $ ) {
	var searchFormSelector = '.search-form-header.collapse';
	var linkSelector = 'a[href="/search/"]';

	$( searchFormSelector + ' + .navbar-nav ' + linkSelector ).on( 'click', function ( e ) {
		$( searchFormSelector ).toggleClass( 'in' );
		setTimeout( function () {
			if ( $( searchFormSelector ).is( '.in' ) ) {
				$( searchFormSelector ).find( 'input[type="text"]' ).trigger( 'focus' );
			}
		}, 100 );
		e.preventDefault();
	} );

	$( 'body' ).on( 'click', function ( e ) {
		if ( $( searchFormSelector ).is( '.in' ) ) {
			if ( $( e.target ).closest( [ searchFormSelector, linkSelector ].join( ',' ) ).length === 0 ) {
				$( searchFormSelector ).removeClass( 'in' );
			}
		}
	} );
} );
