function loadTypeKit(kitId) {
	(function (doc) {
		var config = {
			kitId: kitId,
			scriptTimeout: 3000,
			async: true
		};
		var html = doc.documentElement;
		var errorTimeout = setTimeout(function () {
			html.className = html.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
		}, config.scriptTimeout);
		var script = doc.createElement("script");
		var finished = false;
		var firstScript = doc.getElementsByTagName("script")[ 0 ];
		html.className += " wf-loading";
		script.src = 'https://use.typekit.net/' + config.kitId + '.js';
		script.async = true;
		script.onload = script.onreadystatechange = function () {
			if (finished || this.readyState && this.readyState !== "complete" && this.readyState !== "loaded") {
				return;
			}
			finished = true;
			clearTimeout(errorTimeout);
			try {
				Typekit.load(config);
			} catch (e) { }
		};
		firstScript.parentNode.insertBefore(script, firstScript);
	})(document);
}
